<template>
  <section id="projetos" class="projetos">
    <h2>Projetos e Propostas</h2>
    <div class="card-container">
      <div class="card" v-for="(projeto, index) in projetos" :key="index">
        <div class="card-content">
          <h3>{{ projeto.name }}</h3>
          <p>{{ projeto.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      projetos: [
        {
          name: "Defensora dos Animais",
          description: "Eu sou uma defensora apaixonada pelos direitos dos animais. Acredito que o cuidado com nossos bichinhos não é apenas uma questão de empatia, mas também uma forma de promover respeito e solidariedade na nossa sociedade. Quando cuidamos dos animais, estamos cuidando de todos nós.",
          image: "perfil1.jpg"
        },
        {
          name: "Família Forte",
          description: "A família é o alicerce da nossa sociedade. Defendo políticas que valorizam o papel das famílias, garantindo mais suporte, proteção e condições para que cada membro tenha acesso a oportunidades melhores. Juntos, podemos criar um ambiente onde nossas crianças cresçam com amor, respeito e acesso a uma educação de qualidade.",
          image: "perfil1.jpg"
        },
        {
          name: "Saúde Para Todos",
          description: "Saúde é um direito de todos. No Boa Vista, muitas famílias ainda sofrem com a falta de acesso a serviços de saúde de qualidade. Quero mudar isso. Minha prioridade é garantir que todos tenham atendimento digno e eficaz, desde a prevenção até o tratamento, promovendo campanhas de saúde e melhorias nos nossos postos de atendimento.",
          image: "perfil1.jpg"
        },
        {
          name: "Educação Transformadora",
          description: "Sabemos que a educação é a base para o futuro. Quero lutar por mais investimentos nas escolas públicas, garantir que nossas crianças e jovens tenham acesso ao conhecimento e às ferramentas necessárias para construir suas vidas. Uma educação de qualidade transforma o futuro, e o futuro do Boa Vista depende disso.",
          image: "perfil1.jpg"
        },
        {
          name: "Melhorias no Boa Vista",
          description: "Nosso bairro precisa de melhorias. E eu estou aqui para ouvir vocês e trabalhar em conjunto por essas mudanças. Sabemos das dificuldades que enfrentamos no dia a dia, como as questões de saneamento básico, moradia e infraestrutura. Juntos, vamos transformar o Boa Vista em um lugar melhor para se viver.",
          image: "perfil1.jpg"
        },
        {
          name: "Chamado à Ação",
          description: "Acredito no poder da mudança, mas, acima de tudo, acredito no poder da comunidade. A transformação do nosso bairro e da nossa cidade só será possível com a participação de todos. Como vereadora, estarei ao lado de vocês, lutando por nossas causas e garantindo que o Boa Vista se torne um lugar do qual possamos nos orgulhar.",
          image: "perfil1.jpg"
        }
      ]
    };
  }
};
</script>

<style scoped>
.projetos {
  padding: 2rem;
  background-color: #f9f9f9;
}

h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #004466;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
}

.card {
  flex: 1 1 calc(33% - 1.5rem); /* Ajuste para 3 cartões por linha */
  border: 4px solid rgba(41, 122, 4, 0.8);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  background-color: #fff;
  max-width: 350px; /* Aumente o tamanho máximo para melhor visualização */
}

.card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

img {
  width: 100%;
  height: 200px;
  object-fit: cover; /* Ajuste a imagem para cobrir uniformemente */
}

.card-content {
  padding: 1rem;
  background-color: #e6f7e6;
}

h3 {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: #ff6600;
}

p {
  font-size: 1rem;
  color: #004466;
}

/* Media Query para mobile */
@media (max-width: 768px) {
  .card {
    flex: 1 1 100%;
    margin-bottom: 1.5rem;
  }
}
</style>