<template>
  <section id="galeria" class="galeria">
    <h2>Galeria</h2>
    <div class="carousel-wrapper">
      <div class="carousel">
        <div class="carousel-item" v-for="(image, index) in images" :key="index">
          <img :src="image.src" :alt="image.name">
          <div class="overlay">
            <p>{{ image.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      images: [
        { src: require('@/assets/image01.jpeg'), name: "Imagem 1" },
        { src: require('@/assets/image02.jpeg'), name: "Imagem 2" },
        { src: require('@/assets/image03.jpeg'), name: "Imagem 3" },
        { src: require('@/assets/image04.jpeg'), name: "Imagem 4" },
        { src: require('@/assets/image05.jpeg'), name: "Imagem 5" },
        { src: require('@/assets/image06.jpeg'), name: "Imagem 6" },
        { src: require('@/assets/image07.jpeg'), name: "Imagem 7" },
        { src: require('@/assets/image08.jpeg'), name: "Imagem 8" },
        { src: require('@/assets/image09.jpeg'), name: "Imagem 9" }
      ]
    };
  }
};
</script>

<style scoped>
.galeria {
  padding: 2rem;
  text-align: center;
  background-color: #e6f7f7; /* Fundo verde claro */
}

.galeria h2 {
  color: #ff6600; /* Cor laranja para o título */
  margin-bottom: 1.5rem;
}

.carousel-wrapper {
  overflow: hidden;
  position: relative;
}

.carousel {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch; /* Suporte para rolagem suave em iOS */
  width: 100%; /* Garante que o carrossel ocupe toda a largura do contêiner */
}

.carousel-item {
  position: relative;
  min-width: 200px; /* Largura mínima ajustável */
  max-width: 300px; /* Largura máxima ajustável */
  flex-shrink: 0;
  scroll-snap-align: center;
}

img {
  width: 100%;
  height: 400px; /* Altura fixa para imagens com tamanhos variados */
  border-radius: 5px;
  display: block;
  border: 4px solid rgba(41, 122, 4, 0.8); /* Borda azul escuro */
  object-fit: cover; /* Garante que a imagem cubra o espaço sem distorcer */
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 64, 128, 0.8); /* Fundo azul escuro com transparência */
  color: #fff; /* Texto branco */
  text-align: center;
  padding: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 0 0 5px 5px;
}

.carousel-item:hover .overlay {
  opacity: 1;
}

.overlay p {
  margin: 0;
  font-size: 1rem;
  color: #ff6600; /* Cor laranja para o nome da imagem */
}

/* Estilos específicos para dispositivos desktop */
@media (min-width: 1024px) {
  .carousel-item {
    min-width: 300px; /* Ajuste a largura mínima para desktop */
    max-width: 400px; /* Ajuste a largura máxima para desktop */
  }
}

/* Estilos específicos para dispositivos móveis */
@media (max-width: 768px) {
  .carousel-item {
    max-width: 80vw; /* Largura total da tela para dispositivos móveis */
  }
}
</style>
