<template>
  <section id="quem-sou-eu" class="quem-sou-eu">
    <h2>Quem sou eu</h2>
    <div class="container">
      <div class="photo">
        <img src="../assets/home1.jpeg" alt="Foto do candidato">
      </div>
      <div class="bio">
        <p>Marilene, moradora do bairro Boa Vista em Joinville há 30 anos, é uma líder comunitária dedicada e apaixonada por fazer a diferença. Defensora incansável da causa animal e do fortalecimento das famílias, ela tem uma trajetória marcada pelo compromisso com a proteção dos animais e pela luta por uma sociedade mais justa e acolhedora. Suas ações voluntárias em visitas hospitalares e atividades humanitárias são um reflexo de seu profundo senso de solidariedade e cuidado com o próximo.</p>
        <p>Com uma visão clara de que o progresso real começa nas pequenas ações do dia a dia, Marilene tem trabalhado para melhorar a qualidade de vida no bairro Boa Vista e em Joinville como um todo. Ela acredita que a comunidade precisa de atenção em áreas essenciais, como saúde e educação de qualidade, e busca fortalecer a participação de cada cidadão na construção de um futuro melhor. Sua atuação é movida pela certeza de que, com união e empenho, é possível transformar as realidades mais difíceis.</p>
        <p>Agora, como candidata a vereadora, Marilene está comprometida em ampliar sua atuação. Ela defende a criação de políticas públicas eficientes e a participação ativa da população, com o objetivo de garantir que todos tenham acesso a serviços essenciais e oportunidades de desenvolvimento. Seu trabalho incansável em prol da proteção animal, do apoio às famílias e da promoção da justiça social reflete sua paixão por servir à comunidade.</p>
        <p>Com o apoio dos moradores, Marilene se prepara para continuar essa jornada de transformação. Ela acredita que, juntos, é possível construir um futuro mais digno, seguro e cheio de oportunidades para Boa Vista e para toda Joinville.</p>
      </div>
    </div>
  </section>
</template>

<style scoped>
.quem-sou-eu {
  background-color: #e6f7f7; /* Fundo verde claro */
  padding: 4rem 2rem;
  text-align: center; /* Centralizar o h2 */
}

.quem-sou-eu h2 {
  font-size: 2rem;
  color: #ff6600; /* Laranja para o título */
  margin-bottom: 2rem; /* Espaço abaixo do h2 */
}

.container {
  display: flex;
  flex-direction: column; /* Imagem acima do texto no mobile */
  align-items: center;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.photo img {
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
  object-fit: cover;
  border: 4px solid rgba(41, 122, 4, 0.8); /* Borda azul escuro */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.bio {
  max-width: 800px;
  text-align: justify;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #004080; /* Azul escuro para o texto */
}

.bio p {
  margin-bottom: 0.75rem; /* Espaçamento mais sutil entre parágrafos */
}

@media (min-width: 768px) {
  .container {
    flex-direction: row; /* Imagem ao lado do texto em telas maiores */
    align-items: flex-start;
    gap: 3rem; /* Espaçamento entre imagem e biografia */
  }

  .bio {
    text-align: left;
  }
}
</style>
